<template>
  <div class="mt-4 mb-4">
    <div class="row">
      <div class="pe-5 col-md-5">
        <h6>
          {{
            $t("productos.modoPreparacion.instruccionesHangPack")
          }}
        </h6>
        <p v-html="$t('productos.modoPreparacion.atencionHangPack')" class="border border-3 p-2"></p>
        <p v-html="texto" class="mt-3"></p>
      </div>

      <div class="col-md-3 pe-3 d-flex ">
        <img
          src="@/assets/images/icons/preparacionHangPack.png"
          class="img-fluid align-self-center"
        />
      </div>

      <div class="col-md-4">
        <p v-html="$t('productos.modoPreparacion.videoHangPack')" class="mb-3"></p>
        <div class="justify-content-center">
          <iframe
            width="256"
            height="144"
            src="https://www.youtube.com/embed/ZQ6yArNkiew?si=VFpyrpGxi4coBgwy"
            title="YouTube video player"
            frameborder="0"
            margin="0 auto"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-12 d-flex justify-content-center px-0">
        <img
          src="@/assets/images/icons/lavadomanos.png"
          class="img-fluid h60 me-1"
        />
        <p class="align-self-center txt-celeste txt-iconos">
          {{ $t("productos.higiene") }}
        </p>
      </div>
    </div>
    <div class="row mt-4 text-center">
      <span
        ><em>{{ $t("productos.advertenciasTitulo") }}</em>
        {{ $t("productos.advertencias") }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "ModoPreparacion",
  props: {
    texto: String,
  },
  methods: {
    getImage(fileName) {
      return require("@/assets/images/productos/" + fileName);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/constants.scss";

p {
  font-size: 0.9rem;
  margin-bottom: 0;
}
.txt-iconos {
  text-align: left !important;
  font-size: 0.9rem !important;
  margin: 0;
}

table {
  // width: 100%;
  // border: 1px solid $gris;
  background-color: $gris-claro;
  border: 1px solid white;
  text-align: center;
  font-size: small !important;
  font-weight: lighter !important;
}

thead {
  background-color: lightgray;
}
th {
  font-weight: 400;
  padding: 0.5rem 0 0.5rem 0;
}

td {
  text-align: center;
  vertical-align: middle;
  padding: 10px !important;
  // border: 1px solid $gris;
  border: 1px solid white;
}

table tr > td:first-child {
  text-align: center !important;
}

em {
  color: $celeste;
  font-style: normal !important;
}

iframe {
    display: block !important;
    border-style:none;
    margin: 0 auto;
}
</style>
